import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { mainWhite, screen, mainBlack, subFont } from "../variables";
import IconArrowRightFullCurve from "../../images/svg/arrow-right-full-curve.svg";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  background: ${mainWhite};
  position: relative;

  .slick-slider {
    .slick-list {
      .slick-slide {
        div {
          outline: none;

          .each-slide {
            border: 4px ${mainWhite} solid;
            display: block !important;
            @media ${screen.medium} {
              border-left: 13px ${mainWhite} solid;
              border-right: 13px ${mainWhite} solid;
            }

            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .pointer {
    position: absolute;
    bottom: -30px;
    right: 260px;
    @media ${screen.xxsmall} {
      right: 30%;
      bottom: -32px;
    }

    &__arrow {
      svg {
        width: 65px;
        transform: scaleX(-1) rotate(113deg);
        @media ${screen.xxsmall} {
          width: 72px;
        }
      }
    }

    &__text {
      color: ${mainBlack};
      font-family: ${subFont};
      letter-spacing: 1px;
      font-size: 1.16rem;
      position: absolute;
      width: 160px;
      top: 26px;
      left: 30px;
      line-height: 1.15;
      text-align: center;
      font-weight: 400;

      @media ${screen.xxsmall} {
        font-size: 1.4rem;
        width: 200px;
        top: 30px;
        left: 19px;
      }

      .break {
        display: block;
        @media ${screen.xxsmall} {
        }
      }
    }
  }
`;
const settings = {
  dots: false,
  draggable: false,
  infinite: true,
  speed: 3500,
  autoplaySpeed: 3500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  arrows: false,
  pauseOnHover: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 635,
      settings: {
        slidesToShow: 1,
        speed: 500,
        autoplay: false,
        draggable: true,
        swipeToSlide: true,
      },
    },

    {
      breakpoint: 1160,
      settings: {
        slidesToShow: 3,
        speed: 3500,
        autoplaySpeed: 3500,
      },
    },
  ],
};

const GalleryCarousel = ({ gallery_list }) => {
  return (
    <Wrapper>
      <Slider {...settings}>
        {gallery_list.map((item, i) => (
          <a
            href="https://www.instagram.com/k9_swim"
            target="_blank"
            rel="noreferrer"
            className="each-slide"
            key={i}
          >
            {item.image && (
              <GatsbyImage
                image={item.image.thumbnails.cropped.gatsbyImageData}
                alt={item.image.alt || "Gallery"}
              />
            )}
          </a>
        ))}
      </Slider>

      <span className="pointer" role="presentation">
        <span className="pointer__arrow">
          <IconArrowRightFullCurve />
        </span>
        <a
          href="https://www.instagram.com/k9_swim/"
          target="_blank"
          rel="noreferrer"
          className="pointer__text"
        >
          <span className="break">@k9_swim </span>follow us for more fluffy
          content!
        </a>
      </span>
    </Wrapper>
  );
};

export default GalleryCarousel;

import React from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  mainCyan,
  subFont,
  lightPurple,
  mainPurple,
  mainFont,
  screen,
} from "../components/variables";
import ButtonUnderlined from "../components/buttons/button-underlined";
import IconArrowSquiggly from "../images/svg/arrow-bottom-squiggly.svg";
import IconArrowZigZag from "../images/svg/arrow-right-zigzag.svg";
import GalleryCarousel from "../components/carousel/gallery-carousel";
import TestimonialCarousel from "../components/carousel/testimonial-carousel";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleCyanMd,
} from "../components/circles";
import "video-react/dist/video-react.css";
import { Player } from "video-react";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--intro {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }
    }
  }

  .header {
    margin: 362px 0 0 0;
    @media ${screen.xxsmall} {
      min-height: 850px;
      margin: 0;
    }
    @media ${screen.xsmall} {
      min-height: 800px;
    }
    @media ${screen.small} {
      min-height: 870px;
    }
    @media ${screen.medium} {
      min-height: 930px;
    }
    @media ${screen.large} {
      min-height: 970px;
    }
    @media ${screen.xlarge} {
      min-height: 1000px;
    }

    .col {
      &--text {
        position: relative;
        z-index: 2;
        @media ${screen.xxsmall} {
          width: 46%;
          margin: 300px 0 0 0;
        }
        @media ${screen.xsmall} {
          margin: 0;
        }

        .heading {
          font-weight: 900;
          line-height: 1.09;
          position: relative;
          font-size: 2rem;
          max-width: 350px;
          @media ${screen.xxsmall} {
            font-size: 3.8rem;
          }
          @media ${screen.small} {
            font-size: 5rem;
            max-width: 490px;
          }
        }

        .description {
          max-width: 510px;
          margin: 21px 0;
          @media ${screen.xxsmall} {
            margin: 21px 0 42px 0;
          }

          p {
            font-size: 1.03rem;
            margin: 0 0 21px 0;
            @media ${screen.xxsmall} {
              font-size: 1.11rem;
            }
            @media ${screen.small} {
              font-size: 1.16rem;
              margin: 0 0 21px 0;
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .icon-circle {
          &--dark-purple {
            left: -90px;
            top: -282px;
            width: 128px;
            @media ${screen.xxsmall} {
              right: 0;
              left: auto;
              width: 55px;
              top: auto;
            }
          }
          &--light-purple {
            top: -90px;
            right: 0;
            width: 67px;
            @media ${screen.xxsmall} {
              bottom: -282px;
              left: 175px;
              width: 105px;
              top: auto;
              right: auto;
            }
          }
          &--cyan {
            display: none;
            @media ${screen.xxsmall} {
              display: block;
              bottom: -300px;
              left: -125px;
              width: 250px;
            }
          }
        }
      }
    }

    .dog-artwork {
      position: absolute;
      z-index: 1;
      right: -1px;
      top: 121px;
      width: 260px;
      transition: top 0.15s ease-in-out;
      @media ${screen.xxsmall} {
        width: 920px;
        top: 0;
        right: -47vw;
      }
      @media ${screen.xsmall} {
        width: calc(100px + 70vw);
        max-width: 1200px;
        right: -14vw;
      }
      @media ${screen.small} {
        width: calc(300px + 40vw);
        right: -4vw;
        top: -15px;
      }
      @media ${screen.xlarge} {
        width: calc(300px + 47vw);
        right: -7vw;
        top: -50px;
      }

      .desktop-only {
        display: none;
        @media ${screen.xxsmall} {
          display: block;
        }
      }

      .mobile-only {
        @media ${screen.xxsmall} {
          display: none;
        }
      }
    }
  }

  .intro {
    position: relative;
    z-index: 2;

    .col {
      &--video {
        width: 100%;
        position: relative;
        max-width: 680px;
        margin: 100px 0 0 0;
        @media ${screen.xxsmall} {
          margin: 0;
        }
        @media ${screen.xsmall} {
          width: 54%;
          max-width: none;
          padding: 0 55px 0 0;
        }
        @media ${screen.small} {
          padding: 0 70px 0 0;
        }

        .pointer {
          position: absolute;
          top: -46px;
          right: 80px;
          @media ${screen.xxsmall} {
            right: 110px;
            top: -65px;
          }
          @media ${screen.xsmall} {
            right: 20px;
            top: -46px;
          }

          &__arrow {
            svg {
              width: 42px;
              transform: rotate(-40deg);
              @media ${screen.xxsmall} {
                width: 55px;
              }
              @media ${screen.xsmall} {
                transform: rotate(-10deg);
              }
            }
          }

          &__text {
            font-family: ${subFont};
            letter-spacing: 1px;
            font-size: 1.16rem;
            position: absolute;
            width: 100px;
            bottom: 6px;
            line-height: 1.15;
            left: 52px;
            @media ${screen.xxsmall} {
              font-size: 1.4rem;
              left: 70px;
            }
            @media ${screen.xsmall} {
              bottom: -11px;
              left: 63px;
            }
          }
        }

        .iframe-wrapper {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding: 56.25% 0 0 0;

          .iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: 0;

            .video-react-big-play-button {
              background: ${mainCyan};
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70px;
              @media ${screen.xxsmall} {
                width: 80px;
              }

              &::before {
                top: -1px;
              }
            }

            .video-react-control-bar {
              background: rgba(35, 199, 229, 0.95);
              font-family: ${mainFont};
            }

            .video-react-load-progress {
              div {
                background: ${mainPurple};
              }
            }
          }
        }
      }

      &--text {
        margin: 42px 0 0 0;
        @media ${screen.xsmall} {
          width: 46%;
          margin: 60px 0 0 0;
        }

        .heading {
          font-weight: 900;
          line-height: 1.22;
          font-size: 1.56rem;
          @media ${screen.xxsmall} {
            font-size: 2.5rem;
          }
          @media ${screen.small} {
            font-size: 3rem;
            line-height: 1.19;
          }
        }

        .description {
          margin: 21px 0 21px 0;
          max-width: 590px;
          @media ${screen.xxsmall} {
            margin: 23px 0 38px 0;
          }

          p {
            margin: 0 0 21px 0;
            font-size: 1.02rem;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          strong {
            font-weight: 700;
          }

          a {
            color: ${mainCyan};
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }
    }
  }

  .services {
    margin: 80px 0 20px 0;
    @media ${screen.xxsmall} {
      margin: 130px 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      max-width: 220px;
      position: relative;
      font-size: 1.56rem;
      margin: 0 0 70px 0;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
        max-width: 325px;
        margin: 0;
      }
      @media ${screen.small} {
        line-height: 1.19;
        font-size: 3rem;
        max-width: 380px;
      }

      .pointer {
        position: absolute;
        top: 40px;
        right: 10px;
        @media ${screen.xxsmall} {
          top: 17px;
          right: -21px;
        }

        &__arrow {
          svg {
            width: 40px;
            transform: scaleY(-1) rotate(-5deg);
            @media ${screen.xxsmall} {
              width: 49px;
            }
          }
        }

        &__text {
          font-family: ${subFont};
          letter-spacing: 1px;
          font-size: 1.16rem;
          position: absolute;
          width: 120px;
          bottom: -31px;
          right: -116px;
          font-weight: 400;
          line-height: 1.15;
          @media ${screen.xxsmall} {
            font-size: 1.4rem;
            bottom: -34px;
            right: -114px;
          }
        }
      }
    }

    .service-list {
      display: flex;
      margin: 35px 0 0 0;
      flex-wrap: wrap;
      @media ${screen.xxsmall} {
        margin: 35px -13px 0 -13px;
      }
      @media ${screen.medium} {
        max-width: 1250px;
        margin: 35px 0 0 auto;
      }

      &__each {
        margin: 0 0 60px 0;
        display: flex;
        flex-direction: column;
        @media ${screen.xxsmall} {
          width: calc(33.33% - 26px);
          margin: 0 13px;
        }
        @media ${screen.medium} {
          margin: 0 0 0 26px;
        }

        .title {
          font-weight: 700;
          line-height: 1.4;
          margin: 16px 0 0 0;
          cursor: pointer;
          font-size: 1.25rem;
          @media ${screen.xxsmall} {
            font-size: 1.52rem;
            margin: 23px 0 0 0;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
        }

        .featured-img {
          cursor: pointer;
        }

        .description {
          margin: 12px 0 21px 0;
          padding: 0 18px 0 0;
          font-size: 1.02rem;
          @media ${screen.small} {
            font-size: 1.06rem;
            margin: 18px 0 38px 0;
          }
        }

        .link {
          margin: auto 0 0 0;
        }
      }
    }
  }

  .gallery {
    padding: 0 0 110px 0;
    @media ${screen.xxsmall} {
      padding: 0 0 160px 0;
    }
  }

  .testimonial {
    margin: 0 0 75px 0;
    @media ${screen.xxsmall} {
      margin: 0 0 120px 0;
    }
  }
`;

const IndexPage = ({ data }) => {
  const {
    banner_heading,
    banner_description,
    banner_image_large,
    banner_image_small,
    intro_heading,
    intro_video_preview_thumbnail,
    intro_video,
    intro_description,
    services_heading,
    service_list,
    gallery_list,
    testimonial_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const pageURL = `https://www.k9swim.com.au`;

  return (
    <Layout isHomePage>
      <SEO
        title={title_tag || "Home"}
        description={meta_description}
        url={pageURL}
        isHomePage
      />
      <Wrapper>
        <header className="header">
          <div className="inner-wrapper">
            <div className="col col--text">
              <h1 className="heading">{banner_heading.text}</h1>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
              <ButtonUnderlined label="LET'S CHAT" to="/contact/" />

              <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple" />
              <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple" />
              <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
            </div>
          </div>

          <div className="dog-artwork" id="home-dog-artwork">
            <GatsbyImage
              className="desktop-only"
              image={banner_image_large.gatsbyImageData}
              alt={banner_image_large.alt || "Dog"}
            />

            <GatsbyImage
              className="mobile-only"
              image={banner_image_small.gatsbyImageData}
              alt={banner_image_small.alt || "Dog"}
            />
          </div>
        </header>

        <section className="intro" data-sal="fade" data-sal-duration="700">
          <div className="inner-wrapper inner-wrapper--intro">
            <div className="col col--video">
              <span className="pointer" role="presentation">
                <span className="pointer__arrow">
                  <IconArrowSquiggly />
                </span>
                <span className="pointer__text">play me!</span>
              </span>
              <div className="iframe-wrapper">
                <Player
                  className="iframe"
                  poster={intro_video_preview_thumbnail.url}
                >
                  <source src={intro_video.url} />
                </Player>
              </div>
            </div>
            <div className="col col--text">
              <h2 className="heading">{intro_heading.text}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: intro_description.html }}
              />
              <ButtonUnderlined label="GET TO KNOW US" to="/about/" />
            </div>
          </div>
        </section>

        <section className="services" data-sal="fade" data-sal-duration="700">
          <div className="inner-wrapper">
            <h2 className="heading">
              {services_heading.text}
              <span className="pointer" role="presentation">
                <span className="pointer__arrow">
                  <IconArrowZigZag />
                </span>
                <span className="pointer__text">a splashing good time</span>
              </span>
            </h2>
            <ul className="service-list">
              {service_list.map((item, i) => (
                <li
                  className="service-list__each"
                  key={`${i}${item.heading.text}`}
                >
                  <figure
                    className="featured-img"
                    onClick={() => navigate(`/services/${item.url_slug}/`)}
                  >
                    <GatsbyImage
                      image={
                        item.featured_image.thumbnails.cropped.gatsbyImageData
                      }
                      alt={item.featured_image.alt || "Feautured"}
                    />
                  </figure>
                  <h2
                    className="title"
                    onClick={() => navigate(`/services/${item.url_slug}/`)}
                  >
                    {item.heading.text}
                  </h2>
                  <p className="description">{item.description}</p>
                  <ButtonUnderlined
                    className="link"
                    label="LEARN MORE"
                    to={`/services/${item.url_slug}/`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="gallery">
          <GalleryCarousel gallery_list={gallery_list} />
        </section>

        <section
          className="testimonial"
          data-sal="fade"
          data-sal-duration="700"
        >
          <div className="inner-wrapper">
            <TestimonialCarousel testimonial_list={testimonial_list} />
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        banner_heading {
          text
        }
        banner_description {
          html
        }
        banner_image_large {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        banner_image_small {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        intro_heading {
          text
        }
        intro_description {
          html
        }
        intro_video {
          url
        }
        intro_video_preview_thumbnail {
          url
        }
        gallery_list {
          image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        services_heading {
          text
        }
        service_list {
          heading {
            text
          }
          description
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          url_slug
        }
        testimonial_list {
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          message {
            html
          }
          from_name
        }
        title_tag
        meta_description
      }
    }
  }
`;
